const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    component: () => import("@/views/Home"),
  },
  {
    path: "/enroll", // 招生课程
    title: "招生课程",
    component: () => import("@/views/Enroll"),
  },
  {
    path: "/life", // 学生生活
    title: "学生生活",
    component: () => import("@/views/StudentLife"),
  },
  {
    path: "/digital", // 数字课程
    title: "数字课程",
    component: () => import("@/views/DigitalCourse"),
  },
  {
    path: "/news",
    title: "新闻活动",
    component: () => import("@/views/News1"),
  },
  {
    path: "/news/:id",
    title: "新闻活动",
    component: () => import("@/views/News1/Details"),
  },
  {
    path: "/join",
    title: "加入我们",
    component: () => import("@/views/Join"),
  },
  {
    path: "/notice",
    title: "招标公告",
    component: () => import("@/views/Notice"),
  },
  {
    path: "/notice/:id",
    title: "招标公告",
    component: () => import("@/views/Notice/Detail"),
  },
];

export default routes;
