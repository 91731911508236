const appStore = {
  state: {},
  reducers: {
    // updateState(state, payload) {
    //   return {
    //     ...state,
    //     ...payload,
    //   };
    // },
  },
  effects: {
    // async request() {},
  },
};

export default appStore;
