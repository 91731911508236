import React, { useState, useEffect, useMemo } from "react"
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom"
import { Menu, Carousel } from "antd"
import homeIcon from "@/assets/icon-home.svg"
import homeIconActive from "@/assets/icon-home-active.svg"
import Logo from "@/assets/logo.png"
import banner from "@/assets/home-banner.png"
import styles from "./index.module.less"

export default function Header() {
  const [current, setCurrent] = useState("")
  const location = useLocation()

  const items = [
    {
      label: "首页",
      key: "/index",
      icon: (
        <img
          src={current === "/index" ? homeIconActive : homeIcon}
          alt="home"
          style={{ width: "15px", height: "17px" }}
        />
      ),
    },
    {
      label: "关于我们",
      key: "/about",
    },
    {
      label: "招生课程",
      key: "/enroll",
    },
    {
      label: "新闻与活动",
      key: "/news",
    },
    // {
    //   label: "学生生活",
    //   key: "/life",
    // },
    // {
    //   label: "数字课程",
    //   key: "/digital",
    // },
    {
      label: "加入我们",
      key: "/join",
    },
    {
      label: "招标公告",
      key: "/notice",
    },
  ]

  // 获取当前url
  useEffect(() => {
    setCurrent(location.pathname)
  }, [location.pathname])

  return (
    <>
      <header className={styles.header}>
        <div
          className={styles.logoWrap}
          onClick={() => {
            window.location.href = "/index"
          }}
        >
          <img src={Logo} alt="logo" />
        </div>
        <div className={styles.menusWrap}>
          <Menu
            selectedKeys={[current]}
            mode="horizontal"
            // onClick={(e) => setCurrent(e.key)}
            items={items.map((item) => {
              return {
                key: item.key,
                icon: item.icon,
                label: <Link to={`${item.key}`}>{item.label}</Link>,
              }
            })}
          />
        </div>
      </header>
      <Carousel>
        <div>
          <img src={banner} alt="home" style={{ width: "100%" }} />
        </div>
      </Carousel>
    </>
  )
}
