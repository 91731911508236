import React, { useEffect, useState } from "react"
import { BrowserRouter as Router } from "react-router-dom"
import { useLocation } from "react-router-dom/cjs/react-router-dom"

import Routes from "@/routes"
import Header from "./Header"
import Footer from "./Footer"

export default function BaseLayout() {
  
  return (
    <>
      <Router>
        <Header />
        <Routes />
        <Footer />
      </Router>
    </>
  )
}
