import React, { Suspense, lazy } from "react"
import {
  // BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom"
import routes from "./route"
import Loading from "@/components/Loading"

// 实现组件懒加载 可提升首屏加载速度
const SuspenseComponent = ({ Component, ...props }) => {
  return (
    <Suspense fallback={<Loading /> || "加载中..."}>
      <Component {...props} />
    </Suspense>
  )
}

const renderRouter = (routers) => {
  return (
    <Switch>
      {(routers || []).map((route, index) => {
        const {
          path = "",
          exact = true,
          strict = true,
          redirect = "",
          title = "文谷",
          component = () => {},
        } = route

        // console.log(path, component)
        if (redirect) {
          return (
            <Redirect
              key={path || index}
              exact={exact}
              strict={strict}
              from={path}
              to={redirect}
            />
          )
        }
        return (
          <Route
            key={path || index}
            exact={exact}
            strict={exact}
            path={path}
            render={() => {
              document.title = title
              return <SuspenseComponent Component={lazy(component)} />
            }}
          />
        )
      })}
    </Switch>
  )
}

const RouterConfig = () => {
  return renderRouter(routes)
}

export default RouterConfig
