import React, { useState, useEffect } from "react"
import { Space, Divider } from "antd"
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons"

import Logo from "@/assets/logo-ft.png"
import styles from "./index.module.less"
import { Link } from "react-router-dom/cjs/react-router-dom"

// const items = [
//   {
//     label: "首页",
//     key: "/",
//     icon: (
//       <img
//         src={homeIcon}
//         alt="home"
//         style={{ width: "15px", height: "17px" }}
//       />
//     ),
//   },
//   {
//     label: "关于我们",
//     key: "about",
//   },
//   {
//     label: "招生课程",
//     key: "course",
//   },
//   {
//     label: "新闻与活动",
//     key: "news",
//   },
//   {
//     label: "学生生活",
//     key: "life",
//   },
//   {
//     label: "数字课程",
//     key: "digital",
//   },
//   {
//     label: "加入我们",
//     key: "join",
//   },
// ]

export default function Footer() {
  const [current, setCurrent] = useState("mail")
  const onClick = (e) => {
    console.log("click ", e)
    setCurrent(e.key)
  }

  return (
    <>
      <footer className={styles.footer}>
        <div className={styles.footerTop}>
          <div className={styles.logoWrap}>
            <img src={Logo} alt="logo" />
          </div>
          <div className={styles.links}>
            <Link to="/about">关于我们</Link>
            <Link to="/enroll">招生课程</Link>
            <Link to="/news">新闻与活动</Link>
            {/* <Link to="/life">学生生活</Link> */}
            <Link to="/join">加入文谷</Link>
          </div>
          <Space
            split={<Divider type="vertical" style={{ borderColor: "#999" }} />}
            className={styles.addr}
          >
            <p>地址：驻马店市驿城区白云山大道与团结路交叉口</p>
            <p>招生咨询点：驻马店市置地大道与骏马路交叉口盐业总公司一层</p>
            <p>
              招生电话：孙主任18539665179 吴老师15978833719 冯老师15239642861
            </p>
          </Space>
        </div>
        {/* 浙公网安备 33028202000943号 */}
        <div className={styles.footerBottom}>
          Copyright © 2024 驻马店市第五高级中学 豫ICP备2024062687号
        </div>
      </footer>
    </>
  )
}
