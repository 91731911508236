const routes = [
  {
    path: "/about",
    name: "关于我们",
    title: "关于我们",
    component: () => import("@/views/About")
  },
];

export default routes;
