import React from "react"
import ReactDOM from "react-dom/client"
// import RouterConfig from "./routes"
import { ConfigProvider, App } from "antd"
import zhCn from "antd/es/locale/zh_CN"
import reportWebVitals from "./reportWebVitals"
// import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import store from "./store"
import "dayjs/locale/zh-cn"
import "@/styles/index.less"
import BaseLayout from "@/components/Loyout/BaseLayout"

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <ConfigProvider
    locale={zhCn}
    theme={{
      token: {
        colorPrimary: "#01797E",
      },
      components: {
        Table: {
          headerBg: "#01797E",
          headerColor: "#fff",
        },
        Menu: {
          // Menu
          itemBg: "transparent",
          horizontalItemSelectedColor: "#01797E",
        },
        Timeline: {
          tailColor: "#00787F",
          itemPaddingBottom: "20px",
        },
      },
    }}
  >
    <Provider store={store}>
      <App>
        <BaseLayout />
      </App>
    </Provider>
  </ConfigProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
